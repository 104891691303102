import React from "react";
import { Button } from "@mui/material";
import { FaShareAlt } from "react-icons/fa";
import { RWebShare } from "react-web-share";

export default function Share(propz) {
  const { props } = propz;

  return (
    <React.Fragment>
      <RWebShare
        data={{
          text: props.message,
          url: `https://www.moonwiz.io/post/${props.postID}`,
          title: `Share ${props.userName} Post:`,
        }}
      >
        <Button
          variant="text"
          startIcon={<FaShareAlt style={{ height: "1rem", width: "1rem" }} />}
          fullWidth
          sx={{ borderRadius: 0.5, my: 0.5, p: 0.5 }}
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
        >
          Share
        </Button>
      </RWebShare>
    </React.Fragment>
  );
}
