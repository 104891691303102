import  { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const UsePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

export default UsePageTracking;