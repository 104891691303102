import React, { useState } from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import mixpanel from "mixpanel-browser";
import ReactGA from 'react-ga';

const LIKE_API = "https://api.moonwiz.io/likes/message_id=";
const DISLIKE_API =
  "https://api.moonwiz.io/dislike/message_id=";
const COMMENT_LIKE_API =
  "https://api.moonwiz.io/likes/comment_id=";
const COMMENT_DISLIKE_API =
  "https://api.moonwiz.io/dislike/comment_id=";

const Like = (props) => {
  const [like, setLike] = useState(props.isLiked);
  const [likeCounter, setLikeCounter] = useState(props.likeCounter);

  const [likeComment, setLikeComment] = useState(props.isLikedComment);
  const [commentLikeCounter, setCommentLikeCounter] = useState(
    props.commentLikeCounter
  );
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();

  const LikeHandler = async () => {
    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };

    props.likeComment ? setLikeComment(!likeComment) : setLike(!like);
    props.likeComment
      ? likeComment
        ? setCommentLikeCounter(commentLikeCounter - 1)
        : setCommentLikeCounter(commentLikeCounter + 1)
      : like
      ? setLikeCounter(likeCounter - 1)
      : setLikeCounter(likeCounter + 1);
    axios
      .post(
        props.likeComment
          ? likeComment
            ? COMMENT_DISLIKE_API + props.commentID
            : COMMENT_LIKE_API + props.commentID
          : like
          ? DISLIKE_API + props.postID
          : LIKE_API + props.postID,
        {},
        header
      )
      .then(function (res) {
        if (props.likeComment) {
          mixpanel.track("Liked comment");
          ReactGA.event({ category: "User", action: "Liked comment" });
        } else {
          mixpanel.track("Liked Post");
          ReactGA.event({ category: "User", action: "Liked Post" });
        }
      })
      .catch(function (error) {
        props.likeComment ? setLikeComment(!likeComment) : setLike(!like);
        props.likecomment
          ? likeComment
            ? setCommentLikeCounter(commentLikeCounter - 1)
            : setCommentLikeCounter(commentLikeCounter + 1)
          : like
          ? setLikeCounter(likeCounter - 1)
          : setLikeCounter(likeCounter + 1);
        console.log(error);
      });
  };
  if (props.likeComment) {
    return (
      <Link
        fontSize=".75em"
        fontWeight="600"
        mr={0.7}
        sx={
          likeComment
            ? {
                color: "#82aaff",
                "&:hover": { color: "#89ddff" },
                cursor: "pointer",
                textDecoration: "none",
              }
            : {
                color: "#8F93A2",
                "&:hover": { color: "#89ddff" },
                cursor: "pointer",
                textDecoration: "none",
              }
        }
        onClick={() => {
          isAuthenticated ? LikeHandler() : loginWithRedirect();
        }}
      >
        {commentLikeCounter ? commentLikeCounter + " Like" : "Like"}
      </Link>
    );
  }
  return (
    <Button
      variant="text"
      startIcon={
        like ? <FavoriteRoundedIcon color="error" /> : <FavoriteRoundedIcon />
      }
      fullWidth
      sx={{ borderRadius: 0.5, my: 0.5, p: 0.5 }}
      onClick={() => {
        isAuthenticated ? LikeHandler() : loginWithRedirect();
      }}
    >
      {likeCounter ? likeCounter + " LIKE" : "LIKE"}
    </Button>
  );
};

export default Like;