/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import NoImage from "../../../assets/Wizzo.svg";
import Comments from "./Comments";
import PostMenu from "./PostMenu";
import Like from "./Like";
import Divider from "@mui/material/Divider";
import { FaComment } from "react-icons/fa";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { Avatar, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Linkify from "linkify-react";
import "linkify-plugin-hashtag";
import TimeAgo from "react-timeago";
import Share from "./Share";
import ReactPlayer from "react-player";
import CoinList from "../../../CoinList.json";
// import ImageView from "./ImageView";
import CoinData from "./CoinData";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

// import { usePalette } from "react-palette";

const Post = (props) => {
  // const [visible, setVisible] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const [shortPost] = useState(
    props.message ? props.message.length < 80 : null
  );
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isReadMore, setIsReadMore] = useState(true);
  // const { data } = usePalette(props.messageImage && props.messageImage);
  let navigate = useNavigate();

  const NavigateToSearchResult = (hashtag) => {
    let found = false;
    for (let i = 0; i < CoinList.length; i++) {
      if (
        hashtag === CoinList[i].id ||
        hashtag === CoinList[i].name ||
        hashtag === CoinList[i].symbol
      ) {
        navigate("/coin/" + CoinList[i].id);
        found = true;
        break;
      }
    }
    !found && navigate("/search/" + hashtag);
  };

  return (
    <Box
      className="card"
      display="flex"
      flexDirection="column"
      sx={{ mb: 1, transition: "0.2s" }}
    >
      {/* <ImageView open={visible} close={() => setVisible(false)} props={props} /> */}
      <Box display="flex" justifyContent="space-between">
        <Box width="100%">
          <Link to={"/profile/" + props.channel_id} className="pp-link">
            <Avatar
              sx={{
                height: "2.5rem",
                width: "2.5rem",
                m: 1,
                float: "left",
                "&:hover": { opacity: 0.8 },
              }}
              src={props.channel_pp || NoImage}
              alt="pp"
              className="pp"
            />
            <Typography
              fontFamily="Poppins"
              fontWeight="600"
              fontSize={
                props.userName
                  ? props.userName.length < 20
                    ? ".95rem"
                    : ".90rem"
                  : null
              }
              color="#eeffff"
              mt={1}
              to={"/profile/" + props.channel_id}
              sx={{ "&:hover": { color: "#c792ea" } }}
            >
              {props.userName || "Wizzo Wizay"}
            </Typography>
          </Link>
          <Link to={`/post/${props.postID}`}>
            <Typography
              component={"div"}
              fontSize=".7rem"
              color="#717CB4"
              sx={{ "&:hover": { opacity: 0.8 } }}
            >
              <TimeAgo date={props.timeStamp * 1000} minPeriod={10} />
            </Typography>
          </Link>
        </Box>
        <PostMenu
          isUserPost={props.isUserPost}
          postID={props.postID}
          reload={props.reload}
        />
      </Box>

      <Box width="100%">
        <Typography
          component={"div"}
          mx={1.5}
          mb={0.5}
          fontSize={shortPost && "1.25rem"}
          fontWeight={shortPost ? 600 : 500}
          whiteSpace="pre-line"
          className="post-body"
          color="#eeffff"
        >
          <Linkify
            options={{
              target: "_blank",
              rel: "noopener",
              tagName: { hashtag: "mark" },
              attributes: {
                onClick: (event) =>
                  NavigateToSearchResult(
                    event.target.innerText.substring(1).toLowerCase()
                  ),
              },
            }}
          >
            {isReadMore ? props.message.slice(0, 200) : props.message}

            {props.message.length > 200 && (
              <Typography
                component={"span"}
                onClick={() => setIsReadMore(false)}
                style={{
                  color: "#89ddff",
                  cursor: "pointer",
                  fontWeight: 500,
                }}
              >
                {isReadMore && " ..See more"}
              </Typography>
            )}
          </Linkify>
        </Typography>

        {props.messageImage ? (
          props.mediaType === ".mp4" ? (
            <Box maxHeight="30rem" mx="auto">
              <ReactPlayer
                url={props.messageImage}
                height="100%"
                width="100%"
                loop
                muted
                playing
              />
            </Box>
          ) : (
            <Box maxHeight="30rem" sx={{ cursor: "pointer" }}>
              <Zoom overlayBgColorEnd="#0F111A" wrapStyle={{ width: "100%" }}>
                <img alt="message-img" src={props.messageImage} width="100%" />
              </Zoom>
            </Box>
          )
        ) : null}
      </Box>

      {props.coinData && <CoinData coinData={props.coinData} />}
      <Divider light />
      <Box sx={{ display: "flex", justifyContent: "space-between", mx: 0.5 }}>
        <Like
          likeCounter={props.likeCounter}
          postID={props.postID}
          isLiked={props.isLiked}
        />
        <Button
          variant="text"
          startIcon={<FaComment  style={{height:"1.1rem" ,width:"1.1rem"}}  />}
          // startIcon={<ChatBubbleRoundedIcon/>}
          fullWidth
          sx={{ borderRadius: 0.5, my: 0.5, p: 0.5 }}
          onClick={
            isAuthenticated
              ? () => {
                  setFocusInput(true);
                }
              : () => loginWithRedirect()
          }
        >
          Comment
        </Button>
        <Share props={props} />
      </Box>
      <Comments
        commentsCounter={props.commentsCounter}
        postID={props.postID}
        comments={props.comments}
        reload={props.reload}
        likeCounter={props.likeCounter}
        isLiked={props.isLiked}
        focusInput={focusInput}
        commentLikeCounter={props.commentLikeCounter}
      />
    </Box>
  );
};

export default Post;
