import { Box, IconButton } from "@mui/material";
import React from "react";
import { FaImage } from "react-icons/fa";
import ImageUploading from "react-images-uploading";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

export default function UploadImage(props) {
  return (
    <ImageUploading
      multiple
      value={props.images}
      onChange={(imageList) => props.uploadImage(imageList)}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
        // write your building UI
        <Box display="flex" alignItems="flex-end">
          {imageList.map((image, index) => (
            <Box key={index} position="relative">
              <img src={image["data_url"]} alt="" width="100" />
              <IconButton
                aria-label="send"
                size="small"
                color="error"
                sx={{
                  // color: "#F07178",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={() => onImageRemove(index)}
              >
                <DeleteRoundedIcon fontSize="" />
              </IconButton>
            </Box>
          ))}
          <IconButton
            aria-label="send"
            sx={{
              color: "#717CB4",
      
            }}
            onClick={onImageUpload}
            {...dragProps}
          >
            <FaImage />
          </IconButton>
        </Box>
      )}
    </ImageUploading>
  );
}
