import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextareaAutosize from "react-textarea-autosize";
import "./style.css";
import Flag from "../Flag";
import { Avatar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import { RiFileGifFill } from "react-icons/ri";
import { FaImage } from "react-icons/fa";
import MakePost from "../../../containers/MakePost";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";

export default function MakePostBanner(props) {
  const { isAuthenticated, user } = useAuth0();
  const [openDialog, setOpenDialog] = React.useState(false);

  const openDialogHandler = () => {
    setOpenDialog(!openDialog);
    mixpanel.track("Opened MakePost Banner");
    ReactGA.event({
      category: "User",
      action: "Opened MakePost Banner",
    });
  };
  return (
    isAuthenticated && (
      <Box className="make-post-banner">
        <Flag width="700px">
          <Link
            to={"/profile/" + user.sub}
            style={{ textDecoration: "none" }}
            className="pp-link"
          >
            <Avatar
              sx={{ height: "3.5rem", width: "3.5rem", m: 1, float: "left" }}
              src={user.picture}
              alt="pp"
            />
          </Link>
          <form style={{ position: "relative", display: "flex" }} onClick={openDialogHandler}>
            <TextareaAutosize
              placeholder="write something..."
              className="input-area"
              disabled
            />
            <Box
              onClick={openDialogHandler}
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                right: 16,
                top: 22,
              }}
            >
              <IconButton
                aria-label="send"
                sx={{
                  p: 0.5,
                  color: "#717CB4",
                }}
              >
                <FaImage />
              </IconButton>
              <IconButton
                aria-label="send"
                sx={{
                  p: 0.5,
                  color: "#717CB4",
                }}
              >
                <RiFileGifFill />
              </IconButton>
              <IconButton
                aria-label="send"
                sx={{
                  p: 0.5,
                  color: "#717CB4",
                }}
              >
                <BsFillEmojiLaughingFill />
              </IconButton>
            </Box>
          </form>
        </Flag>
        <MakePost
          open={openDialog}
          close={() => setOpenDialog(false)}
          reload={props.reload}
        />
      </Box>
    )
  );
}
