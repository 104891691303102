import { Box } from "@mui/material";
import React from "react";
import SincePost from "./SincePost";
import TradeRisk from "./TradeRisk";
import Score from "./Score";

export default function CoinData(props) {
  const { coinData } = props;
  // console.log(coinData)
  return (
    <Box display="flex" flexDirection="column" m={1}>
      <SincePost coinData={coinData} />
      <Box display="flex">
        {coinData.is_signal && (
          <>
            <TradeRisk coinData={coinData} />
            <Score coinData={coinData} />
          </>
        )}
      </Box>
    </Box>
  );
}
