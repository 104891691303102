import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/UI/SectionTitle";
import "./style.css";
import { useTheme } from "@mui/system";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function TopCoins(props) {
  const [coins, setCoins] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const getCoinsData = useCallback(() => {
    axios
      .get(
        serverUrl + `coins`
              ,{params: {limit : 10}})
      .then((res) => {
        setCoins(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props]);

  useEffect(() => {
    getCoinsData();
    const interval = setInterval(() => {
      getCoinsData();
    }, 30000);

    return () => clearInterval(interval);
  }, [getCoinsData]);

  return (
    <Box
      sx={
        props.flex
          ? {
              display: "flex",
              flexDirection: "column",
            }
          : {
              position: "fixed",
              right: "1rem",
              top: "4rem",
              width: "18rem",
              display: isMobile ? "none" : "block",
            }
      }
    >
      <SectionTitle type="top-coins" />
      <Box className="card" p={1}>
        {(isMobile ? coins : coins.slice(0,10)).map((coin) => {
          return (
            <Box display="flex" justifyContent="space-between" key={coin.id}>
              <Box display="flex" flexDirection="row" m={0.5}>
                <Avatar
                  src={coin.image}
                  alt="coin"
                  sx={{ height: "2rem", width: "2rem", m: 0.5 }}
                />
                <Box flexDirection="column">
                  <Link
                    to={`/coin/${coin.id}`}
                    onClick={() => {
                      mixpanel.track(
                        "Used Top Coin To Navigate to: " + coin.id
                      );
                      ReactGA.event({
                        category: "User",
                        action: "Used Top Coin To Navigate to: " + coin.id,
                      });
                    }}
                  >
                    <Typography
                      fontFamily="Poppins"
                      color="#eeffff"
                      fontWeight="600"
                      sx={{ "&:hover": { color: "#ffcb6b" } }}
                    >
                      {coin.name}
                    </Typography>
                    <Typography
                      fontFamily="Poppins"
                      fontWeight="700"
                      fontSize=".75rem"
                      color="#717CB4"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {" "}
                      <Typography
                        component={"div"}
                        fontFamily="Poppins"
                        fontWeight="700"
                        fontSize=".75rem"
                        color="#464B5D"
                        display="inline"
                        sx={{ textTransform: "uppercase" }}
                      >
                        #{coin.market_cap_rank + " "}
                      </Typography>
                      {coin.symbol}
                    </Typography>
                  </Link>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" m={1} textAlign="end">
                <Typography
                  fontFamily="Poppins"
                  sx={{ textTransform: "capitalize" }}
                  fontWeight="600"
                >
                  $
                  {coin.current_price &&
                    coin.current_price.toLocaleString("en-US")}
                </Typography>
                <Typography
                  fontSize=".75rem"
                  fontWeight="700"
                  color={
                    coin.price_change_percentage_24h > 0 ? "#c3e88d" : "#f07178"
                  }
                >
                  {coin.price_change_percentage_24h &&
                    coin.price_change_percentage_24h.toFixed(2)}
                  %
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}