import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Loader from "../../components/utils/Loader";
import SectionTitle from "../../components/UI/SectionTitle";
import { Helmet } from "react-helmet";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function Notifications(props) {
  const [notifications, setNotifications] = useState([]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getNotifications = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(serverUrl + "notifications", header)
      .then((res) => {
        setNotifications(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        // handle error
        console.error(err);
        toast.error("Cannot Load notifications");
      });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      getNotifications();
    }
  }, [getNotifications, isAuthenticated]);

  if (props.isMenu) {
    if (notifications === undefined || notifications.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: ".5rem",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography
              color="secondary"
              variant="h6"
              fontSize="1.1rem"
              fontWeight="600"
              mx={1}
              mt={0.75}
            >
              Notifications
            </Typography>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls="long-menu"
              aria-haspopup="true"
            >
              <MoreHorizIcon color="primary" />
            </IconButton>
          </Box>
          <Divider variant="middle" />
          <Loader simple />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: ".5rem",
        }}
      >
        <Helmet>
          <meta name="title" content="Notifications | MoonWiz" />
          <meta name="description" content="Your Notifications" />
        </Helmet>
        <Box display="flex" justifyContent="space-between">
          <Typography
            color="secondary"
            variant="h6"
            fontSize="1.1rem"
            fontWeight="600"
            mx={1}
            mt={0.75}
          >
            Notifications
          </Typography>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-haspopup="true"
          >
            <MoreHorizIcon color="primary" />
          </IconButton>
        </Box>
        {/* <SectionTitle type="notifications" /> */}
        <Divider />

        {notifications.map((notification) => {
          return (
            <Link
              to={`/post/${notification.message_id}`}
              style={{ textDecoration: "none" }}
              key={notification._id.$oid}
            >
              <Box
                sx={{
                  mt: 1,
                  mx: "auto",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "background",
                    opacity: 0.7,
                  },
                  display: "flex",
                }}
              >
                <Avatar src={notification.source_image} sx={{ m: 1 }} />
                <Box display="flex" flexDirection="column">
                  <Typography color="#eeffff" mt={1} fontWeight="600">
                    {notification.source_type === "like_message"
                      ? notification.source_name + " liked your post!"
                      : notification.source_name + " comment on your post!"}
                  </Typography>
                  <Typography component={'div'} color="#c792ea" fontSize=".7rem">
                    <TimeAgo date={notification.timestamp * 1000} />
                  </Typography>
                </Box>
              </Box>
            </Link>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: ".5rem",
      }}
    >
      <SectionTitle type="notifications" />
      {notifications.map((notification) => {
        return (
          <Link
            to={`/post/${notification.message_id}`}
            style={{ textDecoration: "none" }}
            key={notification._id.$oid}
          >
            <Paper
              sx={{
                mt: 1,
                mx: "auto",
                width: "100%",
                "&:hover": {
                  backgroundColor: "background",
                  opacity: 0.7,
                },
                display: "flex",
              }}
            >
              <Avatar src={notification.source_image} sx={{ m: 1 }} />
              <Box display="flex" flexDirection="column">
                <Typography color="#eeffff" mt={1} fontWeight="600">
                  {notification.source_type === "like_message"
                    ? notification.source_name + " liked your post!"
                    : notification.source_name + " comment on your post!"}
                </Typography>
                <Typography component={'div'} color="#c792ea" fontSize=".7rem">
                  <TimeAgo date={notification.timestamp * 1000} />
                </Typography>
              </Box>
            </Paper>
          </Link>
        );
      })}
    </Box>
  );
}