import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Fab, useScrollTrigger, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import mixpanel from "mixpanel-browser";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MakePost from "../../../containers/MakePost";
import ReactGA from "react-ga";
import { animateScroll as scroll } from "react-scroll";

export default function ActionButton(props) {
  const { isAuthenticated } = useAuth0();
  const [openDialog, setOpenDialog] = React.useState(false);

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });


  return (
    <Zoom in={trigger}>
      {isAuthenticated ? (
        <Box
          sx={{
            position: "fixed",
            bottom: { md: 16, xs: 70 },
            right: 16,
            zIndex: 2,
          }}
        >
          <MakePost
            open={openDialog}
            close={() => setOpenDialog(false)}
            reload={props.reload}
          />
          <Fab
            color="secondary"
            size="small"
            aria-label="scroll back to top"
            sx={{ boxShadow: "none" }}
            onClick={() => {
              setOpenDialog(!openDialog);
              mixpanel.track("Used Action Button");
              ReactGA.event({
                category: "User",
                action: "Used Action Button - Make Post",
              });
            }}
          >
            <AddRoundedIcon />
          </Fab>
        </Box>
      ) : (
        <Box
          onClick={() => {
            mixpanel.track("Used Action Button");
            ReactGA.event({
              category: "User",
              action: "Used Action Button - Scroll Top",
            });
            props.reload();
            scroll.scrollToTop();
          }}
          role="presentation"
          sx={{
            position: "fixed",
            bottom: { md: 16, xs: 16 },
            right: 16,
            zIndex: 2,
          }}
        >
          <Fab
            color="secondary"
            size="small"
            aria-label="scroll back to top"
            sx={{ boxShadow: "none" }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      )}
    </Zoom>
  );
}
