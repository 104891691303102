import React, { useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth0 } from "@auth0/auth0-react";
import SectionTitle from "../../components/UI/SectionTitle";
import MakePostBanner from "../../components/UI/MakePostBanner";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "../../components/utils/Loader";
import mixpanel from "mixpanel-browser";
import LoginDialog from "../../components/utils/LoginDialog";
import Posts from "../Posts";
import TopCoins from "../TopCoins";
import HideOnScroll from "../../components/utils/HideOnScroll";
import ActionButton from "../../components/utils/ActionButton";
import ReactGA from "react-ga";
import Error from "../../components/utils/Error";
import { Helmet } from "react-helmet";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    getHomePosts(0, []);
    mixpanel.track("Visit home page");
    ReactGA.event({
      category: "Route",
      action: "Visited Home Page",
    });
    if (isAuthenticated) {
      mixpanel.identify(user.sub);
      mixpanel.track("logged in");
      ReactGA.event({
        category: "User",
        action: "Logged in - user_id: " + user.sub,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHomePosts = (skipVal, postVal) => {
    // console.log("AUTH0: " + JSON.stringify(user))
    axios
      .get(serverUrl + "posts?skip=" + skipVal)
      .then((res) => {
        if (res.data.posts.length === 0) {
          setHasMore(false);
          return;
        }
        setPosts(postVal.concat(res.data.posts));
        setSkip(skipVal + 20);
        mixpanel.track("Scrolled down 20 posts");
        ReactGA.event({
          category: "User",
          action: "Scrolled down 20 posts",
        });
      })
      .catch((err) => {
        // handle error
        console.error(err);
        setError(true);
      });
  };

  //if posts didnt showed up, this is an error message
  if (error) {
    return <Error />;
  }

  if (posts === undefined || posts.length === 0) {
    return (
      <Box maxWidth="700px" mx="auto">
        <SectionTitle type="live" />
        <MakePostBanner />
        <Loader />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Moonwiz | AI Social platform for crypto</title>
        <meta
          name="description"
          content="A social network made for traders. Share and get free crypto coin signals, discuss Bitcoin, NFT and DeFi as we analyze the data and help you profit. "
        ></meta>
      </Helmet>
      <Box maxWidth="700px" mx="auto">
        <HideOnScroll>
          <Box
            position="fixed"
            mx="auto"
            width="98%"
            maxWidth="700px"
            bgcolor="#0f111a"
            zIndex="3"
            sx={{ left: 0, right: 0 }} 
          >
            <SectionTitle type="live" />
          </Box>
        </HideOnScroll>
        <InfiniteScroll
          style={{ marginTop: "2.5rem" }}
          dataLength={posts.length} //This is important field to render the next data
          next={() => {
            getHomePosts(skip, posts);
            setLogged(isAuthenticated);
          }}
          hasMore={hasMore}
          loader={<Loader />}
          refreshFunction={() => getHomePosts(0, [])}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <Typography style={{ textAlign: "center" }} color="primary">
              Need more ah?
            </Typography>
          }
        >
          <MakePostBanner reload={() => getHomePosts(0, [])} />
          <Posts posts={posts} reload={() => getHomePosts(0, [])} />
        </InfiniteScroll>
        {!logged && <LoginDialog open />}
      </Box>
      <ActionButton reload={() => getHomePosts(0, [])} />
      <TopCoins />
    </React.Fragment>
  );
};

export default Home;