import React from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import mixpanel from "mixpanel-browser";
import CoinList from "../../../../CoinList.json";
import { toast } from "react-toastify";

export default function Search(props) {
  const { register, handleSubmit, reset, setFocus } = useForm();
  let navigate = useNavigate();

  const NavigateToSearchResult = (searchQuery) => {
    // console.log(data)
    if (!searchQuery) {
      mixpanel.track("Search Failed");
      return toast.error("u need to write something");
    }
    mixpanel.track("Used Search");
    reset();
    props.closeSearch();
    let found = false;
    for (let i = 0; i < CoinList.length; i++) {
      if (
        searchQuery === CoinList[i].id ||
        searchQuery === CoinList[i].name ||
        searchQuery === CoinList[i].symbol
      ) {
        navigate("/coin/" + CoinList[i].id);
        found = true;
        break;
      }
    }
    !found && navigate("/search/" + searchQuery);
  };

  const SearchStyle = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#717CB430",
    "&:hover": {
      backgroundColor: "#1F2233",
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      // marginLeft: theme.spacing(36),
      width: "500px",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100ch",
      },
    },
  }));

  React.useEffect(() => {
    if (props.openSearch) {
      setFocus("Search");
    }
  }, [props, setFocus]);

  return (
    <SearchStyle
      sx={
        props.openSearch
          ? { display: "flex", mr: 1 }
          : { display: { xs: "none", md: "flex" } }
      }
    >
      <form
        onSubmit={handleSubmit((event) =>
          NavigateToSearchResult(event.Search.toLowerCase())
        )}
      >
        <SearchIconWrapper>
          <SearchIcon color="warning" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          name="Search"
          {...register("Search")}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </SearchStyle>
  );
}
