import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import TextareaAutosize from "react-textarea-autosize";
import "./style.css";
import Comment from "./Comment";
import { Avatar, Divider, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import mixpanel from "mixpanel-browser";
import { IoSend } from "react-icons/io5";
import { toast } from "react-toastify";
import UserDataContext from "../../../../contexts/UserDataContext";

export default function Comments(props) {
  const userData = useContext(UserDataContext);

  const { user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { register, handleSubmit, reset, setFocus } = useForm();
  const [isFocused, setIsFocused] = useState(false);
  const [commentText, setCommentText] = React.useState("");

  useEffect(() => {
    if (props.focusInput) {
      setFocus("Comment");
    }
  }, [setFocus, props]);

  const postComment = async (data) => {
    if (!commentText) {
      return toast.error("u need to write something");
    }

    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };

    const body = {
      message_id: props.postID,
      comment_text: commentText,
    };

    axios
      .post(serverUrl + "comments/post_comment", body, header)
      .then((res) => {
        // console.log(res);
        // window.location.reload(false);
        mixpanel.track("Maked comment");
        setCommentText("");
        props.reload();
        reset();
        toast.success("Success");
      })
      .catch(function (error) {
        // handle error
        mixpanel.track("failed to make comment");
        console.error(error);
        toast.error("Comment failed");
      });
  };

  const handleUserKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(postComment)(); // this won't be triggered
    }
  };

  if (isAuthenticated) {
    return (
      <Box>
        <Divider light />
        <Box className="comments">
          {props.comments
            ? props.comments.map((comment) => {
                if (comment.likes_list) {
                  var isLikedComment;
                  comment.likes_list.forEach((like) =>
                    like.user_id === user.sub
                      ? (isLikedComment = true)
                      : (isLikedComment = false)
                  );
                }
                if (comment.user_id) {
                  var isUserComment;
                  user.sub === comment.user_id
                    ? (isUserComment = true)
                    : (isUserComment = false);
                }

                return (
                  <Comment
                    picture={comment.user_info.user_image}
                    commentBody={comment.comment_text}
                    userName={comment.user_info.user_name}
                    userId={comment.user_id}
                    commentID={comment._id.$oid}
                    postID={props.postID}
                    isLiked={props.isLiked}
                    timeStamp={comment.comment_ts}
                    commentLikeCounter={comment.likes}
                    isLikedComment={isLikedComment}
                    isUserComment={
                      isUserComment ||
                      (userData.permissions && userData.permissions[0])
                    }
                    reload={props.reload}
                  />
                );
              })
            : null}
        </Box>
        <Avatar
          src={user.picture}
          alt="pp"
          sx={{ height: "2rem", width: "2rem", float: "left", m: 1 }}
        />
        <form
          onSubmit={handleSubmit(postComment)}
          autoComplete="off"
          style={{ position: "relative", display: "flex", margin: 0 }}
        >
          <TextareaAutosize
            placeholder="any words?"
            name="comment"
            {...register("Comment")}
            className="comment-input"
            onKeyPress={handleUserKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={commentText}
            onChange={(event) => {
              setCommentText(event.target.value);
            }}
          />
          {(isFocused || commentText) && (
            <IconButton
              aria-label="send"
              sx={{ position: "absolute", right: 10, top: 11 }}
              type="submit"
              color="primary"
              size="small"
            >
              <IoSend size="1.2rem" />
            </IconButton>
          )}
        </form>
      </Box>
    );
  }

  return (
    <Box className="not-logged" onClick={() => loginWithRedirect()}>
      <Box className="comment">
        <Divider light />
        <Box className="comments">
          {props.comments
            ? props.comments.map((comment) => {
                return (
                  <Comment
                    picture={comment.user_info.user_image}
                    commentBody={comment.comment_text}
                    userName={comment.user_info.user_name}
                    channgelId={comment.user_id}
                    commentID={comment._id.$oid}
                    timeStamp={comment.comment_ts}
                    commentLikeCounter={comment.likes}
                  />
                );
              })
            : null}
        </Box>
      </Box>
    </Box>
  );
}
