import React, { useState } from "react";
// import { Grid } from "@giphy/react-components";
// import { GiphyFetch } from "@giphy/js-fetch-api";
import { IconButton } from "@mui/material";
import { RiFileGifFill } from "react-icons/ri";
import ReactGiphySearchbox from "react-giphy-searchbox";
import "./style.css";
// const gf = new GiphyFetch("1WuUI0bAXJnPWtv3l3gcc1dnlhIrwbEm");

export default function Giphy(props) {
  const [openGiphy, setOpenGiphy] = useState(false);

  return (
    <React.Fragment>
      <IconButton
        aria-label="send"
        sx={{
          color: "#717CB4",
        }}
        onClick={() => setOpenGiphy(!openGiphy)}
      >
        <RiFileGifFill />
      </IconButton>
      {openGiphy && (
        <ReactGiphySearchbox
          apiKey="1WuUI0bAXJnPWtv3l3gcc1dnlhIrwbEm" // Required: get your on https://developers.giphy.com
          onSelect={(item) => {
            props.uploadGif(item.images.original_mp4.mp4);
            setOpenGiphy(!openGiphy);
          }}
          searchFormClassName="gif-search"
        />
      )}
    </React.Fragment>
  );
}
