import React from "react";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

export default function SincePost(props) {
      const { coinData } = props;
  return (
    <Paper sx={{p: 0.5, "&:hover": { opacity: 0.8 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Link
          to={`/coin/${coinData.coin_id}`}
          onClick={() => {
            mixpanel.track(
              "Used Post CoinData To Navigate to: " + coinData.coin_id
            );
            ReactGA.event({
              category: "User",
              action: "Used Post CoinData To Navigate to: " + coinData.coin_id,
            });
          }}
        >
          <Box display="flex" flexDirection="row">
            <Avatar
              src={coinData.image}
              alt="coin"
              sx={{
                m: 0.5,
                borderRadius: 0.5,
              }}
            />
            <Typography
              fontFamily="Poppins"
              fontWeight={600}
              color="#ffcb6b"
              px={0.5}
              fontSize=".95rem"
              mt={0.2}
            >
              ${coinData.symbol.toUpperCase()}
              <Typography
                component={"div"}
                display="inline"
                fontFamily="Poppins"
                fontSize=".9rem"
                color="#eeffff"
              >
                {" "}
                Since post:
              </Typography>
              <Typography
                component={"div"}
                color={coinData.price_change_amount > 0 ? "#c3e88d" : "#f07178"}
                fontSize=".9rem"
                fontWeight={500}
                fontFamily="Poppins"
              >
                {`${
                  coinData.price_change_amount > 0 ? "↑" : "↓"
                }${coinData.price_change_amount.toLocaleString(
                  "en-US"
                )} (${coinData.price_change_percentage.toFixed(2)}%)`}
              </Typography>
            </Typography>
          </Box>
        </Link>

        <Box display="flex" px={1}>
          <Box display="flex" flexDirection="column" textAlign="left">
            <Typography
              color="#717CB4"
              fontFamily="Poppins"
              fontWeight={500}
              pr={0.4}
              fontSize=".95rem"
            >
              Then:
            </Typography>
            <Typography
              color="#717CB4"
              fontWeight={500}
              fontFamily="Poppins"
              fontSize=".95rem"
            >
              Now:
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" mt={0.2} textAlign="right">
            <Typography color="#eeffff" fontFamily="Poppins" fontSize=".9rem">
              ${coinData.price_at_post_ts.toLocaleString("en-US")}
            </Typography>
            <Typography color="#eeffff" fontFamily="Poppins" fontSize=".9rem">
              ${coinData.current_price.toLocaleString("en-US")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
