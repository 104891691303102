import React from "react";

const UserDataContext = React.createContext({
  user_id: "",
  user_name: "",
  user_image: "",
  total_posts: Number,
  total_moons: Number,
  permissions: [],
});

export default UserDataContext; 