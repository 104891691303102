import { Box } from "@mui/system";
import mixpanel from "mixpanel-browser";
import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import ReactGA from "react-ga";

export default function SocialIcons() {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <a
        href="https://twitter.com/Moonwiz_io"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          mixpanel.track("Clicked On Social - Twitter");
          ReactGA.event({
            category: "Navigation",
            action: "Clicked On Social - Twitter",
          });
        }}
      >
        <Box sx={{ color: "#717CB480", "&:hover": { color: "#89ddff" } }}>
          <FaTwitter  style={{height:"1.6rem" ,width:"1.6rem"}}  />
        </Box>
      </a>
      <a
        href="https://discord.gg/R9TVmEndha"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          mixpanel.track("Clicked On Social - Discord");
          ReactGA.event({
            category: "Navigation",
            action: "Clicked On Social - Discord",
          });
        }}
      >
        <Box sx={{ color: "#717CB480", "&:hover": { color: "#c792ea" } }}>
          <FaDiscord  style={{height:"1.6rem" ,width:"1.6rem"}}  />
        </Box>
      </a>
      <a
        href="https://www.instagram.com/moonwiz.io/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          mixpanel.track("Clicked On Social - Instagram");
          ReactGA.event({
            category: "Navigation",
            action: "Clicked On Social - Instagram",
          });
        }}
      >
        <Box sx={{ color: "#717CB480", "&:hover": { color: "#f07178" } }}>
          <FaInstagram style={{height:"1.6rem" ,width:"1.6rem"}}  />
        </Box>
      </a>
      <a
        href="https://www.facebook.com/moonwizio/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          mixpanel.track("Clicked On Social - Facebook");
          ReactGA.event({
            category: "Navigation",
            action: "Clicked On Social - Facebook",
          });
        }}
      >
        <Box sx={{ color: "#717CB480", "&:hover": { color: "#82aaff" } }}>
          <FaFacebookF  style={{height:"1.6rem" ,width:"1.6rem"}}  />
        </Box>
      </a>
    </Box>
  );
}
