import React, { Fragment, useCallback, useEffect, useState } from "react";
import Home from "./containers/Home";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import MainAppbar from "./components/layout/MainAppBar";
import Container from "@mui/material/Container";
import Profile from "./containers/Profile";
import { Box } from "@mui/material";
import Tower from "./components/layout/Tower";
import { ThemeProvider } from "@mui/material/styles";
import BottomNav from "./components/layout/BottomNav";
import SearchResult from "./containers/SearchResult/SearchResult";
import Notifications from "./containers/Notifications";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import PostPage from "./containers/PostPage/PostPage";
import CoinPage from "./containers/CoinPage";
import UsePageTracking from "./hooks/UsePageTracking.js";
import { useAuth0 } from "@auth0/auth0-react";
import Theme from "./Theme";
import axios from "axios";
import UserDataContext from "./contexts/UserDataContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopCoins from "./containers/TopCoins";
import TermsOfUse from "./containers/TermsOfUse";
// import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import PWAPrompt from "react-ios-pwa-prompt";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function App() {
  const [userInfo, setUserInfo] = useState([]);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getUserInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const header = {
      headers: {
        Authorization: token,
      },
    };

    axios
      .get(serverUrl + "users", header)
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) getUserInfo();
  }, [isAuthenticated, getUserInfo]);

  return (
    <Fragment>
      <ThemeProvider theme={Theme}>
        <UserDataContext.Provider value={userInfo}>
          <Box className="App" sx={{ mt: { sm: 7, xs: 6 } }}>
            <Router>
              <UsePageTracking />
              <MainAppbar />
              <Tower />
              <div className="planet" />
              {/* <AddToHomeScreen /> */} 
              <PWAPrompt />
              {/* <div className="filter" /> */}
              <Container fluid="true" maxWidth="md" sx={{ px: 0 }}>
                <Routes>
                  <Route path="/" element={<Home />}></Route>
                  <Route
                    path="/notifications"
                    element={<Notifications />}
                  ></Route>
                  <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                  ></Route>
                  <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
                  <Route path="/coin/:coinID" element={<CoinPage />}></Route>
                  <Route
                    path="/search/:searchQuery"
                    element={<SearchResult />}
                  />
                  <Route path="/post/:postId" element={<PostPage />} />
                  <Route path="/topcoins" element={<TopCoins flex full />} />
                  <Route path="/profile/:channel_id" element={<Profile />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </Container>
              <BottomNav />
            </Router>
          </Box>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar
            closeOnClick
            rtl={false}
            draggable
            theme="dark"
            closeButton={false}
            style={{ marginBottom: "1rem" }}
          />
        </UserDataContext.Provider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;