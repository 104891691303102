import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

export default function UploadEmoji(props) {
  const [openPicker, setOpenPicker] = useState(false);
  return (
    <Box>
      {openPicker && (
        <Picker
          theme="dark"
          onSelect={(emoji) => props.addEmoji(emoji.native)}
          color="#82aaff"
          style={{
            position: "fixed",
            marginLeft: "3.5rem",
            width: "22rem",
            // height: "8rem",
            borderRadius: 15,
            backgroundColor: "#1A1C25",
            border: "none",
            color: "red",
          }}
          showPreview={false}
          showSkinTones={false}
        />
      )}
      <IconButton
        sx={{
          color: "#717CB4",
        }}
        onClick={() => setOpenPicker(!openPicker)}
      >
        <BsFillEmojiLaughingFill />
      </IconButton>
    </Box>
  );
}
