import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Auth0ProviderWithHistory from "./components/utils/Auth0Provider";
import App from "./App";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

mixpanel.init("92264d4969be8b224fb07622d4b52cbf", {
  debug: true,
  ignore_dnt: true,
});
ReactGA.initialize("UA-213710356-1");
TagManager.initialize({ gtmId: "GTM-WZLHQ77" });

//------------ IOS Disable Zoom -----------------------
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
  document.body.style.zoom = 1;
});

document.addEventListener("gesturechange", function (e) {
  e.preventDefault();

  document.body.style.zoom = 1;
});
document.addEventListener("gestureend", function (e) {
  e.preventDefault();
  document.body.style.zoom = 1;
});
//------------ IOS Disable Zoom -----------------------

ReactDOM.render(
  <Auth0ProviderWithHistory>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Auth0ProviderWithHistory>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
