import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { HiHome } from "react-icons/hi";
import { FaBell } from "react-icons/fa";
import { IoMdPodium } from "react-icons/io";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import { animateScroll as scroll } from "react-scroll";

export default function LabelBottomNavigation() {
  const [value, setValue] = React.useState("");
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  // const { setFocus } = useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(value);
  };

  return (
    isAuthenticated && (
      <BottomNavigation
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: { md: "none" },
          justifyContent: "space-evenly",
          zIndex: 1,
          textDecoration: "none",
          fontFamily: "Poppins",
          fontWeight: 500,
          backgroundImage: "linear-gradient( transparent, #0f111a)",
        }}
        value={value}
        onChange={handleChange}
        showLabels
      >
        <BottomNavigationAction
          component={Link}
          to="/"
          value=""
          label="Home"
          icon={<HiHome size="2rem" />}
          onClick={() => {
            scroll.scrollToTop();
            mixpanel.track("Clicked on Home Nav");
            ReactGA.event({
              category: "Navigation - Bottom",
              action: "Clicked on Home Nav",
            });
          }}
          sx={{
            "&:hover": {
              color: "#82aaff",
            },
          }}
        />
        <BottomNavigationAction
          component={Link}
          to="/topcoins"
          value="topcoins"
          label="Top Coins"
          icon={<IoMdPodium size="2rem" />}
          sx={{
            "&:hover": {
              color: "#82aaff",
            },
          }}
          onClick={() => {
            mixpanel.track("Clicked on Top Coin Nav");
            ReactGA.event({
              category: "Navigation - Bottom",
              action: "Clicked on Top Coin Nav",
            });
          }}
        />
        <BottomNavigationAction
          component={Link}
          to="/notifications"
          value="notifications"
          label="Notifications"
          icon={<FaBell size="1.5rem" />}
          sx={{
            "&:hover": {
              color: "#82aaff",
            },
          }}
          onClick={() => {
            mixpanel.track("Clicked on Notifications");
            ReactGA.event({
              category: "Navigation - Bottom",
              action: "Clicked on Notifications Nav",
            });
          }}
        />
      </BottomNavigation>
    )
  );
}
