import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { FaBell } from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import { Avatar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import Notifications from "../../../containers/Notifications";
import mixpanel from "mixpanel-browser";
import Search from "./Search";
import ReactGA from "react-ga";
import { animateScroll as scroll } from "react-scroll";

// import CoinList from "../../../CoinList.json";

export default function MainAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNotificationEl, setAnchorNotificationEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openSearch, setOpenSearch] = React.useState(false);
  const { isAuthenticated, logout, user, loginWithRedirect } = useAuth0();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationOpen = Boolean(anchorNotificationEl);

  const handleProfileMenuOpen = (event) => {
    mixpanel.track("Opened Profile Menu");
    ReactGA.event({
      category: "Navigation - Navbar",
      action: "Opened Profile Menu",
    });
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (event) => {
    mixpanel.track("Opened Notification Menu");
    ReactGA.event({
      category: "Navigation - Navbar",
      action: "Opened Notification Menu",
    });
    setAnchorNotificationEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    mixpanel.track("Opened Mobile Menu");
    ReactGA.event({
      category: "Navigation - Navbar",
      action: "Opened Mobile Menu",
    });
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    mixpanel.track("Closed Menu");
    ReactGA.event({
      category: "Navigation - Navbar",
      action: "Closed Menu",
    });
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
    setAnchorNotificationEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = isAuthenticated && (
    <Menu
      sx={{ mt: 6 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
        <MenuItem>
          {
            <Link
              to={"/profile/" + user.sub}
              style={{ textDecoration: "none", display: "flex" }}
              onClick={mixpanel.track("Clicked on Profile")}
            >
              <IconButton color="primary">
                <PersonIcon />
              </IconButton>

              <Typography
                color="#eeffff"
                sx={{ textDecoration: "none", mt: 1 }}
              >
                Profile
              </Typography>
            </Link>
          }
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin });
            mixpanel.track("Clicked on Logout");
          }}
        >
          <IconButton size="large" color="secondary">
            <LogoutIcon />
          </IconButton>
          Log out
        </MenuItem>
    </Menu>
  );
  const notificationId = "notification-account-menu";
  const renderNotification = (
    <Menu
      sx={{ mt: 6 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={notificationId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNotificationOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <Notifications isMenu={isNotificationOpen} />
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = isAuthenticated ? (
    <Menu
      sx={{ mt: 6 }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <IconButton size="large" color="primary">
          <PersonIcon />
        </IconButton>
        <Link
          to={"/profile/" + user.sub}
          style={{ textDecoration: "none" }}
          onClick={() => {
            mixpanel.track("Clicked on Profile");
            ReactGA.event({
              category: "Navigation - Navbar",
              action: "Clicked on Profile",
            });
          }}
        >
          <Typography color="#eeffff" sx={{ textDecoration: "none" }}>
            Profile
          </Typography>
        </Link>
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout({ returnTo: window.location.origin });
          mixpanel.track("Clicked on Logout");
          ReactGA.event({
            category: "Navigation - Navbar",
            action: "Clicked on Logout",
          });
        }}
      >
        <IconButton size="large" color="secondary">
          <LogoutIcon />
        </IconButton>
        Log out
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      sx={{ mt: 6 }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <Link to="/" style={{ textDecoration: "none", color: "white" }}>
          <IconButton size="large" color="primary">
            <HomeIcon />
          </IconButton>
          Home
        </Link>
      </MenuItem>
      <MenuItem
        onClick={() => {
          mixpanel.track("Clicked on Log in ");
          ReactGA.event({
            category: "Navigation - Navbar",
            action: "Clicked on Log in",
          });
          loginWithRedirect();
        }}
      >
        <IconButton size="large" color="secondary">
          <LoginIcon />
        </IconButton>
        Login
      </MenuItem>
      <MenuItem
        onClick={() => {
          mixpanel.track("Clicked on Join ");
          ReactGA.event({
            category: "Navigation - Navbar",
            action: "Clicked on Join",
          });
          loginWithRedirect();
        }}
      >
        <IconButton size="large" color="primary">
          <PersonIcon />
        </IconButton>
        Join
      </MenuItem>{" "}
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        variant="dark"
        className="app-bar"
        sx={{
          width: { lg: "calc(100% - 14rem)" },
          backgroundImage: "linear-gradient( transparent ,#0f111a 60%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link to="/">
            <Button
              sx={
                openSearch
                  ? { display: "none" }
                  : { display: { lg: "none", md: "flex" } }
              }
              onClick={() => {
                scroll.scrollToTop();
                mixpanel.track("Clicked on Navbar Logo");
                ReactGA.event({
                  category: "Navigation - Navbar",
                  action: "Clicked on Navbar Logo",
                });
              }}
            >
              <img src={Logo} alt="title-pic" height="100%" width={100} />
            </Button>
          </Link>
          <Search
            openSearch={openSearch}
            closeSearch={() => setOpenSearch(false)}
          />

          <Box />
          {isAuthenticated ? (
            <Box>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  size="large"
                  aria-label="notifications"
                  onClick={handleNotificationMenuOpen}
                  aria-controls={notificationId}
                  aria-haspopup="true"
                  sx={{ mx: 1 }}
                >
                  <FaBell size="1.2rem" />
                </IconButton>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{ fontFamily: "Montserrat", fontWeight: 600 }}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  startIcon={<Avatar src={user.picture} alt="title-pic" />}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {user.nickname}
                </Button>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={() => {
                    setOpenSearch(true);
                    mixpanel.track("Mobile - clicked search icon");
                    ReactGA.event({
                      category: "Navigation - Navbar",
                      action: "Mobile - clicked search icon",
                    });
                  }}
                  color="warning"
                  sx={
                    openSearch ? { display: { xs: "none", md: "flex" } } : null
                  }
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  sx={{ p: 0, mr: 1 }}
                >
                  <Avatar src={user.picture} alt="title-pic" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <React.Fragment>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Button
                  variant="text"
                  startIcon={<LoginIcon />}
                  onClick={() => {
                    mixpanel.track("Clicked on Log in ");
                    ReactGA.event({
                      category: "Navigation - Navbar",
                      action: "Clicked On log in",
                    });
                    loginWithRedirect();
                  }}
                  color="secondary"
                  sx={{
                    fontFamily: "Poppins",
                    display: { md: "none", lg: "flex" },
                  }}
                >
                  Log In
                </Button>
                <Button
                  variant="contained"
                  startIcon={<PersonIcon />}
                  color="primary"
                  onClick={() => {
                    mixpanel.track("Clicked on Join ");
                    ReactGA.event({
                      category: "Navigation - Navbar",
                      action: "Clicked On Join",
                    });
                    loginWithRedirect();
                  }}
                  sx={{ ml: 1, fontFamily: "Poppins" }}
                >
                  Join
                </Button>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={() => {
                    setOpenSearch(true);
                    mixpanel.track("Mobile - clicked search icon");
                    ReactGA.event({
                      category: "Navigation - Navbar",
                      action: "Mobile - clicked search icon",
                    });
                  }}
                  color="warning"
                  sx={
                    openSearch ? { display: { xs: "none", md: "flex" } } : null
                  }
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderNotification}
    </React.Fragment>
  );
}
