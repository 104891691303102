import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import Wizzo from "../../../assets/Wizzo.svg"

export default function Error() {
  return (
    <Box sx={{ m: "auto" }}>
      <Avatar sx={{ m: "auto", borderRadius: 0 }} src={Wizzo} alt="wizzo" />
      <Typography variant="h6" color="primary" textAlign="center">
        Server is down
      </Typography>
      <Typography variant="h6" color="primary" textAlign="center">
        {"Sorry :("}
      </Typography>
    </Box>
  );
}
