import { Card, CardHeader, CircularProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function Loader(props) {
  if (props.simple) {
    return (
      <Box sx={{ display: 'flex',justifyContent:'center',m:2 }}>
      <CircularProgress />
    </Box>
    );
  }

  return (
    <Box maxWidth="700px" mx="auto">
      <Card sx={{ mb: 1.5 }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width="30%"
              style={{ marginBottom: 2 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="20%" />}
        />
        <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
      </Card>{" "}
      <Card sx={{ mb: 1.5 }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width="30%"
              style={{ marginBottom: 2 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="20%" />}
        />
        <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
      </Card>{" "}
      <Card sx={{ mb: 1.5 }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width="30%"
              style={{ marginBottom: 2 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="20%" />}
        />
        <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
      </Card>
    </Box>
  );
}
