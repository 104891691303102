import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth0 } from "@auth0/auth0-react";
import LoginIcon from "@mui/icons-material/Login";
import Logo from "../../../assets/logo.svg";
import { Box } from "@mui/system";
import './style.css'


export default function LoginDialog(props) {
  const { loginWithRedirect } = useAuth0();
  const [open, setOpen] = React.useState(props.open);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
        fullWidth
    >
      <Box sx={{bgcolor:'#1f2233', '&:hover': {bgcolor:'#1A1C25'}, transition:'0.2s'}}>
        <DialogContent sx={{ width: "14rem", mx: "auto" }}>
          <img src={Logo} alt="wizzo" />
        </DialogContent>
        <DialogTitle sx={{fontSize:'1.5rem',mx:'auto',textAlign:'center'}}>Join to unlock more</DialogTitle>

        <DialogActions sx={{ flexDirection:'column'}}>
          <Button
            variant="contained"
            startIcon={<LoginIcon />}
            onClick={() => loginWithRedirect()}
            color="secondary"
            size="large"
            sx={{fontFamily:'Poppins' }}
          >
            Log In
          </Button>
          <Button
            variant="text"
            
            onClick={() => setOpen(false)}
            size="large"
            sx={{mt:2,color:'white',fontFamily:'Poppins'}}
          >
            next time
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
