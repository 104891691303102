import React from "react";

import { Box, useTheme } from "@mui/system";
import { Avatar, Divider, Typography, useMediaQuery } from "@mui/material";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import { FaTwitter } from "react-icons/fa";

import { FaDiscord } from "react-icons/fa";

export default function UserBadge(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#0F111A",
      }}
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <Avatar
        sx={{
          height: isMobile ? "6rem" : "8rem",
          width: isMobile ? "6rem" : "8rem",
          m: 1,
          mx: "auto",
        }}
        src={props.user_image}
        alt={props.user_name}
      />
      <Box width="fit-content" mx="auto">
        <Typography
          className="user-name"
          fontFamily="Poppins"
          fontWeight="600"
          fontSize="1.25rem"
        >
          {props.user_name}
        </Typography>
        <Divider />
      </Box>
      <Typography fontSize=".9rem" fontFamily="Poppins" color="#717CB4">
        Mooning is but a form of flattery
      </Typography>

      <Box display="flex" mx="auto">
        <a
          href="https://twitter.com/Moonwiz_io"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.track("Clicked On Social - Twitter");
            ReactGA.event({
              category: "Navigation",
              action: "Clicked On Social - Twitter",
            });
          }}
        >
          <Box
            sx={{
              color: "#717CB480",
              "&:hover": { color: "#89ddff" },
              m: 0.5,
            }}
          >
            <FaTwitter size="1.4rem" />
          </Box>
        </a>
        <a
          href="https://discord.gg/R9TVmEndha"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.track("Clicked On Social - Discord");
            ReactGA.event({
              category: "Navigation",
              action: "Clicked On Social - Discord",
            });
          }}
        >
          <Box
            sx={{
              color: "#717CB480",
              "&:hover": { color: "#c792ea" },
              m: 0.5,
            }}
          >
            <FaDiscord size="1.4rem" />
          </Box>
        </a>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#717CB480",
            boxShadow: "inset 0px 4px 16px #717CB4",
            borderRadius: "1rem",
            color: "#c792ea",
            transition: "0.2s",
            p: 1,
            px: 1.5,
            m: 1,
            "&:hover": { opacity: 0.8 },
            width: "8rem",
          }}
        >
          <Typography fontWeight="600">{props.total_posts || 0}</Typography>
          <Divider sx={{ width: "40%", mx: "auto" }} />
          <Typography fontWeight="600" fontFamily="Poppins">
            Posts
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#717CB480",
            boxShadow: "inset 0px 4px 16px #717CB4",
            borderRadius: "1rem",
            transition: "0.2s",
            color: "#89ddff",
            p: 1,
            px: 1.5,
            m: 1,
            "&:hover": { opacity: 0.8 },
            width: "8rem",
          }}
        >
          <Typography fontWeight="600">{props.total_moons || 0}</Typography>
          <Divider sx={{ width: "40%", mx: "auto" }} />
          <Typography fontWeight="600" fontFamily="Poppins">
            Likes
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
