import React from "react";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import { Button, DialogActions } from "@mui/material";
// import { FaFeatherAlt } from "react-icons/fa";
import { Box } from "@mui/system";
import mixpanel from "mixpanel-browser";
import "./style.css";
import UploadImage from "../UploadImage";
import UploadEmoji from "../UploadEmoji";
import { toast } from "react-toastify";
import Giphy from "../Giphy";
import ReactGA from "react-ga";

export default function Inputs(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [postText, setPostText] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [type, setType] = React.useState(".jpg");
  const [imageLink, setImageLink] = React.useState("");
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { register, handleSubmit, reset } = useForm();

  const makePost = async () => {
    if (!postText) return toast.error("u need to write something");

    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };

    const body = imageLink
      ? {
          message_text: postText,
          photo_url: imageLink,
          media_type: type,
        }
      : {
          message_text: postText,
        };

    axios
      .post(serverUrl + "posts", body, header)
      .then((res) => {
        mixpanel.track("Maked post");
        ReactGA.event({
          category: "User",
          action: "Maked post - text: " + postText,
        });
        toast.success("To the Moon!");
        reset();
        props.reload();
        props.close();
      })
      .catch((error) => {
        // handle error
        mixpanel.track("Failed to make post");
        ReactGA.event({
          category: "User",
          action: "Failed to Make Post",
        });
        // console.error("NOT WORKING: " + error);
        toast.error("Failed to make post");
        // props.close();
      });
  };

  const uploadImage = (imageList) => {
    if (imageList.length === 0) {
      setImages(imageList);
      setImageLink("");
      return;
    }

    setImages(imageList);
    var uploadImageFormData = new FormData();
    uploadImageFormData.append("image", imageList[0] && imageList[0].file);
    axios
      .post(serverUrl + "posts", uploadImageFormData)
      .then((res) => {
        setImageLink(res.data);
        mixpanel.track("Uploaded image");
        ReactGA.event({
          category: "User",
          action: "Uploaded Image",
        });
      })
      .catch(function (error) {
        // handle error
        mixpanel.track("Faild to upload image");
        ReactGA.event({
          category: "User",
          action: "Failed Upload Image",
        });
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(makePost)}>
        <TextareaAutosize
          autoFocus
          placeholder="Tell us about the next gem"
          name="Post"
          ref="Post"
          {...register("Post")}
          className="input-area-focus"
          style={{
            border: "0px solid",
            backgroundColor: "#0F111A",
            color: "#eeffff",
            borderRadius: "1rem",
            padding: "1rem",
            marginLeft:".25rem",
            resize: "none",
            width: "90%",
          }}
          minRows={6}
          value={postText}
          onChange={(event) => setPostText(event.target.value)}
        >
        </TextareaAutosize>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            p: 0,
            alignItems: "flex-end",
          }}
        >
          <Box display="flex" alignItems="flex-end">
            <UploadImage
              images={images}
              uploadImage={(imageList) => uploadImage(imageList)}
            />
            <Giphy
              uploadGif={(gifURL) => {
                setImageLink(gifURL);
                setType(".mp4");
              }}
            />
            <UploadEmoji addEmoji={(emoji) => setPostText(postText + emoji)} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            // startIcon={<FaFeatherAlt size="1rem" />}
            sx={{ fontFamily: "Open Sans", fontWeight: 600, m: 1 }}
          >
            Post
          </Button>
        </DialogActions>
      </form>
    </React.Fragment>
  );
}