import React from 'react';
import './style.css';
import Ball from "../../../assets/Ball.png";
import Live from "../../../assets/LiveTitle.png";
import Top10 from "../../../assets/TopCoinTitle.png";
import Notifications from "../../../assets/NotificationsTitle.png";
import { Box } from '@mui/system';


export default function SectionTitle(props) {
    if(props.type === "top-coins"){
        return (
            <Box className="section-title">
                <img src={Ball} alt="ball-left" className="ball-left" />
                <Box className="bar-left" id="topcoins"/>
                <img src={Top10} alt="most" className="topcoins"/>
                <Box className="bar-right" id="topcoins"/>
                <img src={Ball} alt="ball-right" className="ball-right"/>
            </Box>
        )
    }
    if(props.type === "notifications"){
        return (
            <Box className="section-title">
                <img src={Ball} alt="ball-left" className="ball-left"/>
                <Box className="bar-left" />
                <img src={Notifications} alt="top-coins-title" className="notifications"/>
                <Box className="bar-right" />
                <img src={Ball} alt="ball-right" className="ball-right"/>
            </Box>
        )
    }
        return (
            <Box className="section-title" >
                <img src={Ball} alt="ball-left" className="ball-left"/>
                <Box className="bar-left"/>
                <img src={Live} alt="live" className="live" />
                <Box className="bar-right"/>
                <img src={Ball} alt="ball-right" className="ball-right"/>
            </Box>
        )
}

