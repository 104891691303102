import { Avatar, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import WizzoScore from "../../../../../assets/NoImage.svg";
export default function Score(props) {
  const { coinData } = props;
  return (
    <Paper
      sx={{
        m: 1,
        mx: 0,
        ml: 0.5,
        p: 0.5,
        width: "50%",
        "&:hover": { opacity: 0.8 },
      }}
    >
      <Box display="flex" textAlign="center">
        <Avatar src={WizzoScore} />
        <Typography
          component={"div"}
          fontFamily="Poppins"
          fontWeight={500}
          color="#eeffff"
          fontSize=".85rem"
          p={1.25}
        >
          MoonWiz Score:{" "}
          <Typography
            display="inline"
            fontSize=".85rem"
            fontFamily="Poppins"
            color="#ffcb6b"
          >
            {Math.round(coinData.moon_score_at_ts * 100)}/100
          </Typography>
        </Typography>
      </Box>
    </Paper>
  );
}
