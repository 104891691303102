import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Inputs from "./Inputs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";

export default function MakePost(props) {
  const { user, isAuthenticated } = useAuth0();
  const { open, close } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isAuthenticated && (
    <Dialog
      open={open}
      onClose={close}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      className="make-post"
      sx={{ mb: 10, mt: "6rem", borderRadius: 1.5}}
      fullScreen={isMobile}
      PaperProps={{
        style: {
          background: "#1F2233",
          overflow:"hidden"
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" sx={{ p: 1 }}>
        <Avatar src={user.picture}></Avatar>
        <Typography
          fontFamily="Poppins"
          fontWeight="600"
          color="#eeffff"
          m={1}
          flexGrow={1}
        >
          {user.nickname}
        </Typography>
        <IconButton
          sx={{
            color: "#717CB4",
          }}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <Inputs open={open} close={close} reload={props.reload} />
      </DialogContent>
    </Dialog>)
}
