import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import UserDataContext from "../../contexts/UserDataContext";
import Post from "./Post";

export default function Posts(props) {
  const { isAuthenticated,user } = useAuth0();
  const userData = useContext(UserDataContext);

  return (
    <React.Fragment>
      {props.posts.map((post) => {
        if (isAuthenticated) {
          if (post.likes_list) {
            var isLiked;
            post.likes_list.forEach((like) =>
            user.sub === like.user_id
                ? (isLiked = true)
                : (isLiked = false)
            );
          }

          if (post.user_id) {
            var isUserPost;
            user.sub === post.user_id
              ? (isUserPost = true)
              : (isUserPost = false);
          }
        }
        return (
          <Post
            channel_id={post.channel_id ? post.channel_id : post.user_id}
            channel_pp={
              post.channel_image ? post.channel_image : post.user_image
            }
            message={post.message_text}
            userName={post.user_name ? post.user_name : post.channel_nickname}
            timeStamp={post.message_ts}
            likeCounter={post.likes}
            commentsCounter={post.comments_counter}
            comments={post.comments}
            messageImage={post.photo_params && post.photo_params.photo_url}
            mediaType={post.photo_params && post.photo_params.media_type}
            postID={post._id.$oid}
            key={post._id.$oid}
            isLiked={isLiked}
            reload={props.reload}
            isUserPost={
              isUserPost || (userData.permissions && userData.permissions[0])
            }
            coinData={post.coin_data}
          />
        );
      })}
    </React.Fragment>
  );
}
