import { Box } from "@mui/system";
import React from "react";

import "./style.css";

export default function Flag(props) {


  return (
      <Box className="flag">
        <Box className="pipe" />
        <Box className="floopies" />
        <Box className="right-floop" />
        <Box className="flag-card" >
          {props.children}
        </Box>
      </Box>
  );
}
