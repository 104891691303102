import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import axios from "axios";
import { Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function PostMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { getAccessTokenSilently } = useAuth0();

  const deletePost = async (postID) => {
    handleClose();
    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };

    axios
      .delete(
        serverUrl + `posts/${props.postID}`,
        header
      )
      .then((res) => {
        toast.success("Post Deleted");
        props.reload();
      })
      .catch((err) => {
        // handle error
        toast.error("Post delete Failed");
        console.error(err);
      });
  };

  const copyLink = async () => {
    handleClose();
    await navigator.clipboard.writeText(
      `https://www.moonwiz.io/post/${props.postID}`
    );
    toast.success("Link Copied");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ float: "right" }}>
      <IconButton onClick={handleClick} sx={{ color: "#717CB4" }}>
        <MoreHorizIcon />
      </IconButton>
      {props.isUserPost ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleClose}>
            <EditIcon color="primary" />
            <Typography pl={0.2}>Edit</Typography>
          </MenuItem>
          <MenuItem onClick={deletePost}>
            <DeleteIcon color="primary" />
            <Typography pl={0.2}>Delete</Typography>
          </MenuItem>{" "}
          <Divider variant="middle" />
          <MenuItem onClick={copyLink}>
            <LinkRoundedIcon color="primary" />
            <Typography pl={0.4}>Copy Link</Typography>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={copyLink}>
            <LinkRoundedIcon color="primary" />
            <Typography pl={0.4}>Copy Link</Typography>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
}