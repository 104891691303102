import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Linkify from "linkify-react";
import React, {  useState } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import Like from "../../Like";
import CommentMenu from "./CommentMenu";

export default function Comment(props) {
  const [isShowMenu, setIsShowMenu] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsShowMenu(true)}
      onMouseLeave={() => setIsShowMenu(false)}
    >
      <Box m={0.5} display="flex" alignItems="center">
        <Link to={"/profile/" + props.userId}>
          <Avatar
            src={props.picture}
            alt="pp"
            sx={{ height: "2rem", width: "2rem", m: 0.5 }}
          />
        </Link>
        <Box
          sx={{
            px: 1.5,
            py: 0.75,
            bgcolor: "#717CB430",
            "&:hover": { bgcolor: "#717CB480" },
            transition: "0.2s",
            borderRadius: 1.5,
            maxWidth: "fit-content",
          }}
        >
          <Link
            to={"/profile/" + props.userId}
            style={{ textDecoration: "none" }}
          >
            <Typography
              fontSize=".9rem"
              fontWeight="600"
              fontFamily="Poppins"
              color="#eeffff"
              m={0}
              className="pp-user-comment-name"
            >
              {props.userName}
            </Typography>
          </Link>
          <Typography component={'div'} fontWeight="400" fontSize=".9rem" color="#eeffff">
            <Linkify
              options={{
                target: "_blank",
                rel: "noopener",
                tagName: { hashtag: "mark" },
                className: "comment-body-link",
              }}
            >
              {props.commentBody}
            </Linkify>
          </Typography>
        </Box>
        {isShowMenu && (
          <CommentMenu
            reload={props.reload}
            commentID={props.commentID}
            isUserComment={props.isUserComment}
          />
        )}
      </Box>

      <Box display="flex" ml={6.5}>
        <Like
          likeComment
          postID={props.postID}
          commentID={props.commentID}
          commentLikeCounter={props.commentLikeCounter}
          isLikedComment={props.isLikedComment}
        />
        <Typography component={'div'} fontSize=".75rem" color="#8F93A2" fontWeight="600">
          {"• "}
          <TimeAgo date={props.timeStamp * 1000} minPeriod={10} />
        </Typography>
      </Box>
    </Box>
  );
}
