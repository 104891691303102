import React, { useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Wizzo from "../../assets/Wizzo.svg";
import SectionTitle from "../../components/UI/SectionTitle";
import { Avatar, Typography, Button, Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/utils/Loader";
import mixpanel from "mixpanel-browser";
import Posts from "../Posts";
import TopCoins from "../TopCoins";
import ActionButton from "../../components/utils/ActionButton";
import HideOnScroll from "../../components/utils/HideOnScroll";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Error from "../../components/utils/Error";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const SearchResult = (props) => {
  const [posts, setPosts] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);

  const params = useParams();
  //   const [param,setParams] = useState(null);

  useEffect(() => {
    mixpanel.track("Visit Search Results");
    ReactGA.event({
      category: "Route",
      action: "Visited SearchResult - Query: " + params,
    });
    setPosts([]);
    setSkip(0);
    getSearchResult(skip, posts);
    // eslint-disable-next-line
  }, [params]);

  const getSearchResult = (skipVal, postVal) => {
    axios
      .get(
        serverUrl + `posts/search/${params.searchQuery}&skip=${skipVal}`
      )
      .then((res) => {
        if (res.data.posts.length === 0) {
          setHasMore(false);
          return;
        }
        setPosts(postVal.concat(res.data.posts));
        setSkip(skipVal + 20);
      })
      .catch((err) => {
        // handle error
        console.error(err);
        setError(true);
      });
  };

  //if posts didnt showed up, this is an error message
 if (error) {
   return <Error />;
 }

  if (posts.length === 0) {
    if (!hasMore) {
      return (
        <Box sx={{ m: "auto", my: 2, width: "30%", textAlign: "center" }}>
          <Avatar sx={{ m: "auto", borderRadius: 0 }} src={Wizzo} alt="wizzo" />
          <Typography variant="h5" color="primary" textAlign="center">
            No Results
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button variant="outlined" color="secondary">
              back home
            </Button>
          </Link>
        </Box>
      );
    }
    return (
      <Box maxWidth="700px" mx="auto">
        <SectionTitle type="live" />
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <Helmet>
        <title>{`${params.searchQuery} | MoonWiz Search`}</title>
        <meta
          name="description"
          content={`MoonWiz Search result Page for ${params.searchQuery}`}
        />
      </Helmet>{" "}
      <Box maxWidth="700px" mx="auto">
        <HideOnScroll>
          <Box
            position="fixed"
            mx="auto"
            maxWidth="700px"
            width="100%"
            bgcolor="#0f111a"
            zIndex="3"
            sx={{ pr: { xs: 2, md: 0 } }}
          >
            <SectionTitle type="live" />
          </Box>
        </HideOnScroll>
        <InfiniteScroll
          style={{ marginTop: "2.5rem" }}
          dataLength={posts.length} //This is important field to render the next data
          next={() => getSearchResult(skip, posts)}
          hasMore={hasMore}
          loader={<Loader />}
          refreshFunction={() => getSearchResult(0, [])}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
              <Typography style={{ textAlign: "center" }} color="primary">
                Need more ah?
              </Typography>
          }
        >
          <Posts posts={posts} reload={() => getSearchResult(0, [])} />
        </InfiniteScroll>
      </Box>
      <ActionButton reload={() => getSearchResult(0, [])} />
      <TopCoins />
    </Box>
  );
};

export default SearchResult;