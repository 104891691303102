import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Wizzo from "../../assets/Wizzo.svg";
import SectionTitle from "../../components/UI/SectionTitle";
import { Avatar, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/utils/Loader";
import Posts from "../Posts";
import TopCoins from "../TopCoins";
import CoinBanner from "./CoinBanner";
import ActionButton from "../../components/utils/ActionButton";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Error from "../../components/utils/Error";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const CoinPage = (props) => {
  const [posts, setPosts] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);
  const [coin, setCoin] = useState({});
  const params = useParams();

  const getCoinData = useCallback(() => {
    axios
      .get(serverUrl + `coins/${params.coinID}`)
      .then((res) => {
        setCoin(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.coinID]);

  useEffect(() => {
    getCoinData();
  }, [getCoinData, params]);

  const getCoinPosts = useCallback(
    (skipVal, postVal) => {
      axios
        .get(
          serverUrl + `posts/search/${params.coinID}`
        )
        .then((res) => {
          if (res.data.posts.length === 0) {
            setHasMore(false);
            return;
          }
          setPosts(postVal.concat(res.data.posts));
          setSkip(skipVal + 20);
        })
        .catch((err) => {
          // handle error
          console.error(err);
          setError(true);
        });
    },
    [params]
  );

  useEffect(() => {
    getCoinPosts(0, []);
    mixpanel.track("Visit Coin page");
    ReactGA.event({
      category: "Route",
      action: "Visited Coin Page - Coin: " + params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

 if (error) {
   return <Error />;
 }

  if (posts.length === 0) {
    if (!hasMore) {
      return (
        <Box sx={{ mx: "auto", my: 2, width: "20rem", textAlign: "center" }}>
          <Avatar sx={{ m: "auto", borderRadius: 0 }} src={Wizzo} alt="wizzo" />
          <Typography variant="h5" color="primary" textAlign="center">
            No Results
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button variant="outlined" color="secondary">
              back home
            </Button>
          </Link>
        </Box>
      );
    }
    return (
      <Box maxWidth="700px" mx="auto">
        <SectionTitle type="live" />
        <Loader />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" mx="auto" maxWidth="750px">
      <Helmet>
        <title>{`${coin.symbol.toUpperCase()} | $${
          coin.current_price && coin.current_price.toLocaleString("en-US")
        }`}</title>
        <meta
          name="description"
          content={`${coin.name} is currently trading at $${
            coin.current_price && coin.current_price.toLocaleString("en-US")
          }. Latest ${coin.name} Price analysis and Signals at MoonWiz`}
        />
        <link rel="canonical" href={coin.image} />
      </Helmet>
      <CoinBanner coin={coin} />
      <Box mx="auto" width="100%">
        <SectionTitle type="live" />
        <InfiniteScroll
          dataLength={posts.length} //This is important field to render the next data
          next={() => getCoinPosts(skip, posts)}
          hasMore={hasMore}
          loader={<Loader />}
          refreshFunction={() => getCoinPosts(0, [])}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <Typography style={{ textAlign: "center" }} color="primary">
              Need more ah?
            </Typography>
          }
        >
          <Posts posts={posts} reload={() => getCoinPosts(0, [])} />
        </InfiniteScroll>
      </Box>
      <TopCoins />
      <ActionButton reload={() => getCoinPosts(0, [])} />
    </Box>
  );
};

export default CoinPage; 