import { Box } from "@mui/material";
import React from "react";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";
import "./Watch.css";

export default function Watch(props) {
  return (
    <Box className="watch" height="20rem">
      <TradingViewWidget
        // widgetType="MediumWidget"
        symbol={props.symbol + "usd"}
        theme={Themes.DARK}
        locale="en"
        autosize
        className="watch-view"
        style={BarStyles.LINE}
        hide_top_toolbar
        toolbar_bg="#1F2233"
        enable_publishing={false}
        hide_legend
        save_image={false}
      />
    </Box>
  );
}
