import { Avatar, Box, Typography, Button, Divider } from "@mui/material";
import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import Logo from "../../../assets/logo.svg";
import "./style.css";
import { Link } from "react-router-dom";
import SocialIcons from "../../utils/SocialIcons";
import mixpanel from "mixpanel-browser";
import UserDataContext from "../../../contexts/UserDataContext";
import ReactGA from "react-ga";
import WelcomeImg from "../../../assets/WelcomeImage.svg";
import { animateScroll as scroll } from "react-scroll";

export default function Tower(props) {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const userData = useContext(UserDataContext);

  return (
    <Box className="tower">
      <Link to="/">
        <Button
          onClick={() => {
            mixpanel.track("Clicked on Tower Logo");
            ReactGA.event({
              category: "Navigation - Tower",
              action: "Clicked On Tower Logo",
            });
            scroll.scrollToTop();
          }}
          sx={{ positiion: "aboslute", top: 12, left: 40, height: "1.9rem" }}
        >
          <img src={Logo} alt="title-pic" height="100%" />
        </Button>
      </Link>
      {isAuthenticated ? (
        <Box>
          <Box
            sx={{
              "&:hover": {
                backgroundColor: "background",
                opacity: 0.7,
              },
            }}
          >
            <Link
              to={"/profile/" + user.sub}
              onClick={() => {
                mixpanel.track("Clicked on Tower User Info");
                ReactGA.event({
                  category: "Navigation - Tower",
                  action: "Clicked On User Info",
                });
              }}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Avatar
                alt="user-pic"
                src={user.picture}
                sx={{
                  width: "4rem",
                  height: "4rem",
                  ml: 9,
                  mt: 8,
                }}
              />
              <Divider sx={{ ml: 5, mt: 1.5, width: "8rem" }} />
              <Typography
                textAlign="center"
                variant="h6"
                color="primary"
                fontWeight={600}
                sx={{ mr: 9 }}
              >
                {user.nickname || "Wizzo Wizay"}
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              ml: 5,
              mt: 6.5,
              height: "4rem",
              width: "8rem",
              overflow: "visible",
            }}
          >
            <Box
              sx={{
                bgcolor: "#1F2233",
                color: "#c792ea",
                p: 0.5,
                px: 1.5,
                m: 0.2,
                "&:hover": { bgcolor: "#1A1C25" },
                transition: "0.2s",
                overflow: "visible",
              }}
              className="card"
            >
              <Typography fontWeight="600">
                {userData.total_posts || 0}
              </Typography>
              <Divider />
              <Typography fontWeight="600" fontFamily="Poppins">
                Posts
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "#1F2233",

                color: "#89ddff",
                p: 0.5,
                px: 1.5,
                m: 0.2,
                "&:hover": { bgcolor: "#1A1C25" },
                transition: "0.2s",
                overflow: "visible",
              }}
              className="card"
            >
              <Typography fontWeight="600">
                {userData.total_moons || 0}
              </Typography>
              <Divider />
              <Typography fontWeight="600" fontFamily="Poppins">
                Likes
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <React.Fragment>
          <Box textAlign="center" ml="2.25rem" mt="3rem" width="9rem">
            <img src={WelcomeImg} alt="christmess-icon" height={70}/>
            <Typography
              color="primary"
              fontFamily="Montserrat"
              fontWeight="800"
              fontSize="1.15rem"
            >
              Welcome to MoonWiz
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 6,
              ml: 5.5,
              width: "8rem",
              height: "4.5rem",
            }}
          >
            <Button
              variant="contained"
              startIcon={<PersonIcon />}
              color="primary"
              onClick={() => {
                loginWithRedirect();
                mixpanel.track("Clicked on Tower Join");
                ReactGA.event({
                  category: "Navigation - Tower",
                  action: "Clicked On Join",
                });
              }}
              sx={{ m: 0.5, fontFamily: "Poppins" }}
            >
              Join
            </Button>
            <Button
              variant="contained"
              startIcon={<LoginIcon />}
              onClick={() => {
                loginWithRedirect();
                mixpanel.track("Clicked on Tower Log in");
                ReactGA.event({
                  category: "Navigation - Tower",
                  action: "Clicked On Log in",
                });
              }}
              color="secondary"
              fontFamily="Poppins"
              sx={{ px: 1, m: 0.5, fontFamily: "Poppins" }}
            >
              Log In
            </Button>
          </Box>
        </React.Fragment>
      )}
      <Box
        sx={{
          position: "fixed",
          left: 20,
          bottom: 15,
          zIndex: 5,
          width: "12rem",
        }}
      >
        <SocialIcons />
      </Box>
      <Box
        fontSize=".7rem"
        position="absolute"
        bottom={2}
        left={30}
        color="#717CB480"
      >
        <Link className="privacy-links" to="/privacy-policy">
          Privacy Policy{"  "}
        </Link>
        {"  •  "}
        <Link to="/terms-of-use" className="privacy-links">
          {"  "} Terms of Use
        </Link>
      </Box>
    </Box>
  );
}
