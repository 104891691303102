import { Box } from "@mui/system";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../components/utils/Loader";
import SectionTitle from "../../components/UI/SectionTitle";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Post from "../Posts/Post";
import { Helmet } from "react-helmet";
import Error from "../../components/utils/Error";
const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function PostPage(props) {
  const [post, setPost] = useState([]);
  const [error, setError] = useState(false);
  const { user } = useAuth0();
  const params = useParams();

  const getPost = useCallback(() => {
    axios
      .get(serverUrl + `posts/${params.postId}`)
      .then((res) => {
        setPost(res.data);
      })
      .catch((err) => {
        // handle error
        console.error(err);
        setError(true);
      });
  }, [params]);

  useEffect(() => {
    getPost();
  }, [getPost, params]);

  //if posts didnt showed up, this is an error message
 if (error) {
   return <Error />;
 }

  if (post === undefined || post.length === 0) {
    return (
      <Box maxWidth="700px" mx="auto">
        <SectionTitle type="live" />
        <Loader />
      </Box>
    );
  }

  return (
    <Box maxWidth="700px" mx="auto">
      <Helmet>
        <title>{`${
          post.user_name ? post.user_name : post.channel_nickname
        } | MoonWiz`}</title>
        <meta name="description" content={`${post.message_text}`} />
        <link rel="canonical" href={(post.photo_params || 0 || {}).photo_url} />
      </Helmet>
      <SectionTitle type="live" />
      {post.map((post) => {
        if (user) {
          if (post.likes_list) {
            var isLiked;
            post.likes_list.forEach((like) =>
              like.user_id === user.sub ? (isLiked = true) : (isLiked = false)
            );
          }
          if (post.user_id) {
            var isUserPost;
            user.sub === post.user_id
              ? (isUserPost = true)
              : (isUserPost = false);
          }
        }
        return (
          <Post
            channel_id={post.channel_id ? post.channel_id : post.user_id}
            channel_pp={
              post.channel_image ? post.channel_image : post.user_image
            }
            message={post.message_text}
            userName={post.user_name ? post.user_name : post.channel_nickname}
            timeStamp={post.message_ts}
            likeCounter={post.likes}
            commentsCounter={post.comments_counter}
            comments={post.comments}
            messageImage={(post.photo_params || 0 || {}).photo_url}
            postID={post._id.$oid}
            key={post._id.$oid}
            isLiked={isLiked}
            isUserPost={isUserPost}
          />
        );
      })}
    </Box>
  );
}