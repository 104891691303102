import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserBadge from "../../components/UI/UserBadge";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import Loader from "../../components/utils/Loader";
import mixpanel from "mixpanel-browser";
import Posts from "../Posts";
import ActionButton from "../../components/utils/ActionButton";
import { Helmet } from "react-helmet";
import TopCoins from "../TopCoins";
import SectionTitle from "../../components/UI/SectionTitle";

const Profile = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [userInfo, setUserInfo] = useState([]);
  const [posts, setPosts] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const params = useParams();

  const getUserData = useCallback(() => {
    axios
      .get(serverUrl + `users/${params.channel_id}`)
      .then((res) => {
        // setPosts(res.data.messages);
        setUserInfo(res.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }, [params, serverUrl]);

  const getUserPosts = useCallback(
    (skipVal, postVal) => {
      axios
        .get(
          serverUrl +
            "posts/user/" +
            params.channel_id +
            "&skip=" +
            skipVal
        )
        .then((res) => {
          // console.log("ULTIMATE: " + JSON.stringify(res));
          if (res.data.posts.length === 0) {
            setHasMore(false);
            return;
          }
          setPosts(postVal.concat(res.data.posts));
          setSkip(skipVal + 20);
        })
        .catch((err) => {
          // handle error
          console.error(err);
        });
    },
    [params, serverUrl]
  );

  useEffect(() => {
    mixpanel.track("Visited Profile Page");
    if (isAuthenticated) mixpanel.identify(user.sub);
    getUserData();
    getUserPosts(0, []);
  }, [getUserData, getUserPosts, isAuthenticated, user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box maxWidth="700px" mx="auto">
      {userInfo && (
        <Helmet>
          <title>{`${
            userInfo.user_nickname || userInfo.user_name
          } | MoonWiz`}</title>
          <meta
            name="description"
            content={`${
              userInfo.user_nickname || userInfo.user_name
            } on MoonWiz.
          Latest Post: ${posts.length !== 0 && posts[0].message_text}`}
          />
        </Helmet>
      )}
      <UserBadge
        user_id={userInfo.sub}
        user_image={userInfo.user_image}
        user_name={userInfo.user_nickname || userInfo.user_name}
        total_moons={userInfo.total_moons}
        total_posts={userInfo.total_posts}
      />
      <SectionTitle/>
      <InfiniteScroll
        dataLength={posts.length} //This is important field to render the next data
        next={() => getUserPosts(skip, posts)}
        hasMore={hasMore}
        loader={<Loader />}
      >
        <Posts posts={posts} reload={() => getUserPosts(0, [])} />
      </InfiniteScroll>
      <TopCoins />
      <ActionButton reload={() => getUserPosts(0, [])} />
    </Box>
  );
};

export default Profile;