import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#82aaff",
      },
      secondary: {
        main: "#c792ea",
      },
      background: {
        default: "#0F111A",
        paper: "#1F2233",
      },
      error: {
        main: "#ff5370",
      },
      warning: {
        main: "#f78c6c",
      },
      info: {
        main: "#89ddff",
      },
      success: {
        main: "#ffcb6b",
      },
      divider: "#4B526D",
    },
    shape: {
      borderRadius: 13,
    },
    props: {
      MuiAppBar: {
        color: "transparent",
      },
    },
    typography: {
      fontFamily: "Open Sans,sans-serif",
      color:"#eeffff",
      button: {
        fontFamily: "Oswald",
        fontWeight: "600",
      },
      h6: {
        fontFamily: "Poppins",
      },
      h5: {
        fontFamily: "Poppins",
      },
      h3: {
        fontFamily: "Poppins",
      },
      h4: {
        fontFamily: "Poppins",
      },
      h1: {
        fontFamily: "Poppins",
      },
      h2: {
        fontFamily: "Poppins",
      },
    },
  });


  export default Theme;