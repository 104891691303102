import React from "react";
import { Avatar, Paper, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import Watch from "../../../components/utils/Watch/Watch";
import { useTheme } from "@mui/system";

export default function CoinBanner(props) {
  const {coin} = props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <React.Fragment>
      <Box className="card" mb={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          p={1}
        >
          <Box display="flex" flexGrow={1}>
            <Avatar
              src={coin.image}
              alt="coin"
              sx={{ height: "4rem", width: "4rem", m: 1 }}
            />
            <Box mt={1.5}>
              <Typography fontFamily="Poppins" fontWeight="600" variant="h5">
                {coin.name}
              </Typography>
              <Typography fontFamily="Poppins" fontWeight="500" color="#464B5D">
                Rank #{coin.market_cap_rank}
              </Typography>
            </Box>
            <Paper
              sx={{ height: "fit-content", p: 0.5, px: 1, mt: 1.5, mx: 1 }}
            >
              <Typography
                fontFamily="Poppins"
                fontWeight="700"
                color="#717CB4"
                sx={{ textTransform: "uppercase" }}
              >
                {coin.symbol}
              </Typography>
            </Paper>
          </Box>
          <Box m={1} sx={isMobile && { width: "100%" }}>
            <Typography color="#717CB4" fontSize=".8rem" fontWeight="600">
              {coin.name} Price:
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography fontFamily="Poppins" fontWeight="600" variant="h5">
                $
                {coin.current_price &&
                  coin.current_price.toLocaleString("en-US")}
              </Typography>
              <Paper
                sx={
                  coin.price_change_percentage_24h > 0
                    ? {
                        bgcolor: "#c3e88d",
                        height: "fit-content",
                        p: 0.5,
                        px: 1,
                      }
                    : {
                        bgcolor: "#f07178",
                        height: "fit-content",
                        p: 0.5,
                        px: 1,
                      }
                }
              >
                <Typography
                  fontFamily="Poppins"
                  fontWeight="600"
                  color="#0F111A"
                  sx={{ textTransform: "uppercase" }}
                >
                  {coin.price_change_percentage_24h &&
                    coin.price_change_percentage_24h.toFixed(2)}
                  %
                </Typography>
              </Paper>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              mt={1}
            >
              <Typography
                fontWeight="600"
                color="#717CB4"
                fontSize=".9rem"
                mr={1.5}
              >
                24h Volume:
              </Typography>
              <Typography fontSize=".9rem" fontWeight="600">
                $
                {coin.market_cap_change_24h &&
                  coin.market_cap_change_24h.toLocaleString("en-US")}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              mt={1}
            >
              <Typography
                color="#717CB4"
                fontSize=".9rem"
                mr={1.5}
                fontWeight="600"
              >
                Market Cap:
              </Typography>
              <Typography fontSize=".9rem" fontWeight="600">
                ${coin.market_cap && coin.market_cap.toLocaleString("en-US")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Watch symbol={coin.symbol} />
      </Box>
    </React.Fragment>
  );
}
