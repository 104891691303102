import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from "react";
import GaugeChart from "react-gauge-chart";

export default function TradeRisk(props) {
  const { coinData } = props;
  return (
    <Paper
      sx={{
        m: 1,
        mx: 0,
        mr: 0.5,
        p: 0.5,
        width: "50%",
        "&:hover": { opacity: 0.8 },
      }}
    >
      <Box display="flex" textAlign="center">
        <Avatar
          sx={{ bgcolor: "transparent", width: "3.5rem" }}
          variant="square"
        >
          <GaugeChart
            style={{
              paddingLeft: "-1rem",
              //   marginTop: ".5rem",
            }}
            percent={coinData.moon_score_at_ts}
            nrOfLevels={5}
          />
        </Avatar>

        <Typography
          fontFamily="Poppins"
          component={"div"}
          fontWeight={500}
          color="#eeffff"
          fontSize=".85rem"
          pt={1.25}
        >
          Trade Risk:{" "}
          <Typography
            display="inline"
            fontSize=".85rem"
            fontFamily="Poppins"
            color="#ffcb6b"
          >
            {coinData.trade_risk_at_ts}
          </Typography>
        </Typography>
      </Box>
    </Paper>
  );
}
