import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function CommentMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { getAccessTokenSilently } = useAuth0();

  const deleteComment = async () => {
    handleClose();
    const token = await getAccessTokenSilently();
    const header = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .delete(
        serverUrl + `comments/delete_comment?comment_id=${props.commentID}`,
        header
      )
      .then((res) => {
        toast.success("Post Deleted");
        // console.log(res)
        props.reload();
      })
      .catch((err) => {
        // handle error
        toast.error("Post delete Failed");
        console.error(err);
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    props.isUserComment ? (
      <React.Fragment>
        <IconButton
          sx={{ color: "#717CB4" ,m:.5}}
          size="small"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreHorizRoundedIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              deleteComment();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </React.Fragment>
    ) : null
  );
}
